/* eslint-disable no-param-reassign */
import { createUUID } from './alooma';

/**
 * This utility modifies the passed in "client" object
 * rather than creating a new object with modified properties.
 * Not modifying arguments is considered a best practice, so why
 * isn't it observed here?
 *
 * The "client" object that is passed is not a "plain ol' object".
 * Instead, it is an instance of the AloomaLib client class. Thus,
 * spreading this client object into a new one looses its connection to the
 * AloomaLib prototype and the methods contained within it. This method
 * of modifying the existing object is the easiest way to maintain that
 * prototypal inhertance.
 */
export default (client) => {
  const oldTrack = client.track.bind(client);
  const oldTrackPageview = client.track_pageview.bind(client);
  // eslint-disable-next-line default-param-last
  client.track = (eventName, properties = {}, callback) => {
    const messageId = createUUID();
    oldTrack(eventName, { ...properties, messageId }, callback);
  };
  client.track_pageview = (page) => {
    const messageId = createUUID();
    oldTrackPageview(page, { messageId });
  };
  return client;
};
