/* eslint-disable no-param-reassign */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable rover/no-platform-specific-globals-or-imports */
import * as amplitude from '@amplitude/analytics-browser';
import type { ValidPropertyType } from '@amplitude/analytics-types';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const PAGE_VIEW_EVENT_NAME = 'page-view';
const OLD_PAGE_VIEW_EVENT_NAME = 'mp_page_view';

// Handle an amplitude.init() bug when localStorage is unavailable
const isLocalStorageAvailable = (): boolean => {
  const test = '__localstorageavailabletest__';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

const sendEventToAmplitude = (eventName: string, properties = {}): void => {
  if (window.Rover?.eventstreamEnableAmplitude) {
    // Do not send old pageview events to Amplitude
    if (eventName !== OLD_PAGE_VIEW_EVENT_NAME) {
      amplitude.track(eventName, properties);
    } else {
      amplitude.track(PAGE_VIEW_EVENT_NAME, properties);
    }
  }
};

type TrackReturnValues = {
  event: string;
  properties: Record<string, unknown>;
};

interface EventstreamClient {
  track: (
    eventName: string,
    properties?: Record<string, unknown>,
    callback?: () => void
  ) => TrackReturnValues;
  track_pageview: (page?: string, properties?: Record<string, unknown>) => void;
  amplitudeInit: (
    apiKey: string,
    amplitudeOptions: Record<string, unknown>,
    sessionReplayConfig?: Record<string, unknown>
  ) => void;
  amplitudeConfigure: (
    userId: string | undefined,
    userProperties: Record<string, ValidPropertyType>,
    eventProperties?: Record<string, ValidPropertyType>
  ) => void;
}

const AloomaToAmplitudeFieldRenamer = (
  inputProperties: Record<string, unknown> = {}
): Record<string, unknown> => {
  const mapAloomaNameToAmplitudeName = {
    $os: 'client_os',
    $browser: 'client_browser',
    $current_url: 'client_current_url',
    $browser_version: 'client_browser_version',
    $screen_height: 'client_screen_height',
    $screen_width: 'client_screen_width',
    $search_engine: 'client_search_engine',
    // Note: the _referring_domain fields do not have _client prefix
    $initial_referrer: 'client_initial_referrer',
    $initial_referring_domain: 'initial_referring_domain',
    $referrer: 'client_referrer',
    $referring_domain: 'referring_domain',
    timezone_offset: 'client_timezone_offset',
    user_agent: 'client_user_agent',
    pageCategory: 'page_category',
    messageId: 'message_id',
  };
  const mappedProperties: Record<string, unknown> = {};
  Object.keys(inputProperties).forEach((key: string) => {
    // Change the field name in the output data if the field needs to be renamed
    const mappedKey = mapAloomaNameToAmplitudeName[key] || key;
    const originalValue = inputProperties[key];
    mappedProperties[mappedKey] = originalValue;
  });
  return mappedProperties;
};

const AloomaToAmplitudeFieldExcluder = (
  inputProperties: Record<string, unknown> = {}
): Record<string, unknown> => {
  const excludedFields = [
    '$lib_version',
    'environment',
    'mp_page',
    'mp_lib',
    'mp_browser',
    'mp_platform',
    'eh',
    'token',
  ];
  const mappedProperties: Record<string, unknown> = {};
  Object.keys(inputProperties).forEach((key: string) => {
    if (!excludedFields.includes(key)) {
      mappedProperties[key] = inputProperties[key];
    }
  });
  return mappedProperties;
};

const deriveClientUriPath = (clientCurrentUrl: string): string => {
  const url = new URL(clientCurrentUrl);
  return url.pathname;
};

export const AloomaToAmplitudeFieldDeriver = (
  inputProperties: Record<string, unknown> = {}
): Record<string, unknown> => {
  const mappedProperties: Record<string, unknown> = {};
  Object.keys(inputProperties).forEach((key: string) => {
    mappedProperties[key] = inputProperties[key];
    if (key === 'client_current_url') {
      mappedProperties.client_uri_path = deriveClientUriPath(inputProperties[key] as string);
    }
  });
  return mappedProperties;
};

/**
 * "Decorate" the eventstream client with a function that sends events to Amplitude,
 * before calling the original "track()" method.
 *
 * Also add an "initialize Amplitude" hook to the client API.
 */

const withAmplitude = (client: EventstreamClient): EventstreamClient => {
  let configuredEventProperties = {};
  const oldTrack = client.track.bind(client);

  client.amplitudeInit = (
    apiKey: string,
    amplitudeOptions: Record<string, unknown>,
    sessionReplayConfig: Record<string, unknown> = {}
  ) => {
    // Uncatchable promise chain error in amplitude.init if localStorage is unavailable
    // The error in init() prevents all events from being fired anyway
    // Therefore, skip init() call if localStorage is unavailable
    if (isLocalStorageAvailable()) {
      amplitude.init(apiKey, amplitudeOptions);
      // Session Replay Plugin
      // config specifies e.g. 'sampleRate: 0.50', which is 50 percent of sessions captured
      // if no 'sampleRate' provided, default config {} disables Session Replay
      const sessionReplayTracking = sessionReplayPlugin(sessionReplayConfig);
      amplitude.add(sessionReplayTracking);
    }
  };

  client.amplitudeConfigure = (
    userId: string | undefined,
    userProperties: Record<string, ValidPropertyType>,
    eventProperties: Record<string, ValidPropertyType> = {}
  ) => {
    if (userId) {
      amplitude.setUserId(userId);
    }
    const identifyEvent = new amplitude.Identify();
    Object.keys(userProperties).forEach((key) => {
      identifyEvent.set(key, userProperties[key]);
    });
    amplitude.identify(identifyEvent);
    configuredEventProperties = { ...eventProperties };
  };

  // eslint-disable-next-line default-param-last
  client.track = (eventName, eventProperties = {}, callback) => {
    const sentData = oldTrack(
      eventName,
      { ...eventProperties, ...configuredEventProperties },
      callback
    );

    const { event, properties } = sentData;
    // Do not send "observability_only" events to Amplitude - they go only to Datadog
    if (!properties.observability_only) {
      const withExcludedProperties = AloomaToAmplitudeFieldExcluder(properties);
      const withRenamedProperties = AloomaToAmplitudeFieldRenamer(withExcludedProperties);
      const withDerivedProperties = AloomaToAmplitudeFieldDeriver(withRenamedProperties);
      sendEventToAmplitude(event, withDerivedProperties);
    }
    return sentData;
  };

  return client;
};

export default withAmplitude;
