/* eslint-disable no-param-reassign */
/**
 * This utility modifies the passed in "client" object
 * rather than creating a new object with modified properties.
 * Not modifying arguments is considered a best practice, so why
 * isn't it observed here?
 *
 * The "client" object that is passed is not a "plain ol' object".
 * Instead, it is an instance of the AloomaLib client class. Thus,
 * spreading this client object into a new one looses its connection to the
 * AloomaLib prototype and the methods contained within it. This method
 * of modifying the existing object is the easiest way to maintain that
 * prototypal inhertance.
 */

interface AloomaClient {
  track: (eventName: string, properties?: Record<string, unknown>, callback?: () => void) => void;
  track_pageview: (page?: string, properties?: Record<string, unknown>) => void;
}

const withPageCategory = (client: AloomaClient): AloomaClient => {
  const oldTrack = client.track.bind(client);
  const oldTrackPageview = client.track_pageview.bind(client);
  // eslint-disable-next-line default-param-last
  client.track = (eventName, properties = {}, callback) => {
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    const pageCategory = window?.Rover?.pageCategory;
    const includePageCategory = pageCategory ? { pageCategory } : {};
    oldTrack(eventName, { ...properties, ...includePageCategory }, callback);
  };
  client.track_pageview = (page?: string) => {
    // eslint-disable-next-line rover/no-platform-specific-globals-or-imports
    const pageCategory = window?.Rover?.pageCategory;
    const includePageCategory = pageCategory ? { pageCategory } : {};
    oldTrackPageview(page, { ...includePageCategory });
  };
  return client;
};

export default withPageCategory;
