import alooma from './alooma';
import withAmplitude from './withAmplitude';
import withMessageId from './withMessageId';
import withPageCategory from './withPageCategory';

if (process.env.JS_ENV_CLIENT) {
  const initEventStream = () => {
    alooma('eventstream');

    window.eventstream = withPageCategory(withMessageId(withAmplitude(window.eventstream)));
    window.alooma = window.eventstream;
    window.eventstream.execute_preload_calls(window.Rover?.bootloaderCalls || []);
  };

  initEventStream();
}
