import _ from 'underscore';

/*
 * This is the truncate function from the alooma legacy JS.
 *
 * malformed inputs were causing infinite recursion here,
 * so we've added a maxRecursionLevel.  And unit tests.
 *
 * Original Docstring:
 *
 * this function returns a copy of object after truncating it.  If
 * passed an Array or Object it will iterate through obj and
 * truncate all the values recursively.
 *
 */
const truncate = (
  obj: unknown,
  length: number,
  maxRecursionLevel = 5,
  currentLevel = 0
): unknown => {
  if (currentLevel >= maxRecursionLevel) {
    if (typeof obj === 'string') {
      return 'truncated';
    }
    if (_.isArray(obj)) {
      return ['truncated'];
    }
    if (_.isObject(obj)) {
      return { truncated: true };
    }
    return obj;
  }

  let ret;

  if (typeof obj === 'string') {
    ret = obj.slice(0, length);
  } else if (_.isArray(obj)) {
    ret = [];
    _.each(obj, (val) => {
      ret.push(truncate(val, length, maxRecursionLevel, currentLevel + 1));
    });
  } else if (_.isObject(obj)) {
    ret = {};
    _.each(obj, (val, key) => {
      ret[key] = truncate(val, length, maxRecursionLevel, currentLevel + 1);
    });
  } else {
    ret = obj;
  }

  return ret;
};

export default truncate;
